import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { CircularGridLines, XYPlot, Hint, HorizontalGridLines, MarkSeries, VerticalGridLines, XAxis, FlexibleXYPlot } from "react-vis";
import { isEqual } from "lodash";
import { SizeMe } from "react-sizeme";
import TotalClientMapWrapper from "./index.style";
import "react-vis/dist/style.css";
import { useEffect } from "react";
import { returnVehicle } from "../../utils/returnVehicle";

// style for hint popup
const tipStyle = {
  background: "rgba(255,255,255,1)",
  border: "1px solid rgba(245,245,245,1)",
  borderRadius: "2px",
  boxShadow: "-3px 16px 30px 0 rgba(134,134,123,0.16)",
  color: "rgba(0,0,0,.65)",
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  padding: "12px",
};

// chart config
const chartHeight = 500;
const chartMargin = {
  bottom: 0,
  left: 0,
  right: 40,
  top: 0,
};

const colorData = [];
colorData["Energizer"] = "#D2A530";
colorData["Liberator"] = "#9B4BEB";
colorData["Protector"] = "#D230AF";
colorData["Observer"] = "#30ABD2";

const TotalClientMap = (props) => {
  const { data, newscenterclass, dataLoading, allowClick = true } = props;
  const [hoveredClient, setHoveredClient] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);
  const [mapData, setMapData] = useState([]);

  const onValueClick = ({ id, practiceName }) => {
    if (allowClick) {
      localStorage.setItem("current_practice", practiceName);
      localStorage.setItem("current_practice_id", id);
      setRedirectTo(`/${practiceName}/clients/view/${id}`);
    }
  };

  const onValueMouseOut = () => {
    setHoveredClient(false);
  };

  const onValueMouseOver = (value) => {
    return !isEqual(value, hoveredClient) ? setHoveredClient(value) : false;
  };

  useEffect(() => {
    // console.log("data", data);
    if (dataLoading) return;
    const insights = data;
    const chartData = [];
    if (insights.length == 0) {
      setMapData([]);
      return;
    }
    // loop
    insights.forEach((i, index) => {
      if (i.insights.personality.type)
        chartData.push({
          id: i.id,
          color: colorData[i.insights.personality.type],
          currentRetirementVehicle: i.insights.profile.currentRetirementVehicle,
          name: `${i.firstName} ${i.lastName}`,
          practice: i.practice,
          practiceName: i.practiceName,
          x: i.insights.score.riskBehaviorScore,
          y: i.insights.score.plScore,
        });
    });
    setTimeout(() => {
      setMapData(chartData);
    }, 0);
  }, [data, dataLoading]);

  return (
    <TotalClientMapWrapper>
      <div className="top-wrapper">
        <div className="bottom-bar">
          <div className="bottom-item">
            <div className="inner">{/* <div>Protector</div>
                <div>Observer</div> */}</div>
          </div>
          <div className="bottom-item">
            <div className="inner">{/* <div>Liberator</div>
                <div>Energizer</div> */}</div>
          </div>
        </div>
      </div>
      <div className="chart-wrapper">
        <SizeMe>
          {({ size }) => (
            <div className="chart">
              <FlexibleXYPlot height={chartHeight} margin={{ left: 10, right: 40 }} xDomain={[0, 100]} yDomain={[-100, 100]} dontCheckIfEmpty={true}>
                <CircularGridLines centerX={50} tickValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90]} style={{ zIndex: 1 }} />
                <XAxis marginTop={28.5} style={{ zIndex: 2 }} />
                {/* <VerticalGridLines style={{ strokeDasharray: "4" }} tickValues={[25, 50, 75]} /> */}
                <HorizontalGridLines style={{ strokeDasharray: "4" }} tickValues={[0]} />
                <MarkSeries colorType="literal" data={mapData} onValueClick={onValueClick} onValueMouseOut={onValueMouseOut} onValueMouseOver={onValueMouseOver} sizeRange={[5, 15]} strokeWidth={0} style={{ cursor: "pointer" }} />
                {/* <XAxis top={chartHeight - 10} tickValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]} style={{ width: "50%" }} /> */}
                {hoveredClient ? (
                  <Hint value={hoveredClient}>
                    <div style={tipStyle}>
                      <div style={{ fontWeight: "600" }}>{hoveredClient.name}</div>
                      <div>{hoveredClient.practice}</div>
                      <div>{returnVehicle[hoveredClient.currentRetirementVehicle]}</div>
                    </div>
                  </Hint>
                ) : null}
              </FlexibleXYPlot>
            </div>
          )}
        </SizeMe>
        <div className={`label-wrapper ${newscenterclass ? newscenterclass : ""}`}>
          <div>
            <span>Logical</span>
          </div>
          <div>
            <span>Balanced</span>
          </div>
          <div>
            <span>Passionate</span>
          </div>
        </div>
      </div>
      {/* <div className="bottom-wrapper">
          <div className="item">Conservative</div>
          <div className="item">Moderately Conservative</div>
          <div className="item">Moderate</div>
          <div className="item">Moderately Aggressive</div>
          <div className="item">Aggressive</div>
        </div> */}
      {redirectTo !== null && <Redirect push to={redirectTo} />}
    </TotalClientMapWrapper>
  );
};

export default TotalClientMap;
