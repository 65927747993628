import React, { useContext, useRef, useState } from "react";
import Slider from "react-slick";
import decode from "jwt-decode";
import { Link, Redirect } from "react-router-dom";
import { Row, Col, Form, Input, Button, Drawer, Menu, Rate, Icon, Popover, Card, Avatar, Divider, Select } from "antd";
import { LandingDataWrapper, MenuWrapper } from "./index.style";
import "../../theme/globalStyle";
import { Container, Modal, OverlayTrigger, Popover as PopoverLanding } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLazyQuery, useQuery } from "react-apollo";
import gql from "graphql-tag";
import _ from "lodash";
import { Sidebar, TotalClientMap } from "../../components";
import { UserCardWrapper } from "../../components/userMenu/index.style";
import IntlMessages from "../../helpers/intlMessages";
import { AccountContext } from "../../context/AccountContext";
import InviteClient from "../../components/inviteForm/inviteClient";
import { useEffect } from "react";
import AssessmentModal from "../dashboard/components/assessmentModal";
import { clientMapData } from "../../utils/plotData";
import infoIcon from "../../assets/img/info-blue.svg";
import InvitePractice from "../../components/inviteForm/invitePractice";
import InviteAssessment from "../../components/inviteForm/inviteAssessment";
import InviteAdvisor from "../../components/inviteForm/inviteAdvisor";
import desktopLogo from "../../assets/img/unitifi-logo.png";
import moment from "moment";
const { Option } = Select;

const popover = (
  <PopoverLanding id="popover-basic-landing">
    <PopoverLanding.Header as="h3">Team Discounts</PopoverLanding.Header>
    <PopoverLanding.Body>
      <ul>
        <li>
          <span>25%</span> off for <span>11-50</span> team members
        </li>
        <li>
          <span>30%</span> off for <span>51-100</span> team members
        </li>
        <li>
          <span>40%</span> off for <span>101-250</span> team members
        </li>
        <li>
          <span>60%</span> off for <span>250+</span> team members
        </li>
      </ul>
    </PopoverLanding.Body>
  </PopoverLanding>
);

const Landing = (props) => {
  const { history, me, user, location, currentPortalId, currentPractice } = props;
  const testimonialRef = useRef();
  const [collapsed, setCollapsed] = useState(false);
  const [collapsedSide, setCollapsedSide] = useState(true);
  const [isAssessmentModal, setAssessmentModal] = useState(false);
  const [isAssessmentViewAllModal, setAssessmentViewAllModal] = useState(false);
  const [isClientModal, setClientModal] = useState(false);
  const [assessmentOrder, setAssessmentOrder] = useState(null);
  const [totalAssessment, setTotalAssessment] = useState(null);
  const [assessmentSearch, setAssessmentSearch] = useState(null);
  const [select, setSelect] = useState();
  const [menuModal, setMenuModal] = useState(false);
  const [assessmentViewType, setAssessmentViewType] = useState("completed");
  const userContext = useContext(AccountContext);
  const [exceedalert, setExceedalert] = useState(false);
  const assessmentRef = useRef();

  const { isPracticeInviteModal, setPracticeInviteModal, setAdvisorModal, isAdvisorModal, logoUpdate, setLogoUpdate, setAssessmentChange } = userContext;

  const menuToggle = () => {
    setCollapsed(!collapsed);
  };

  const menuToggleSide = () => {
    setCollapsedSide(!collapsedSide);
  };

  const { loading: logoDetailLoading, data: logoDetail, refetch: logoRefetch } = useQuery(
    gql`
      query($roleCode: String, $practiceId: Int, $portalId: Int) {
        getLogoByRole(roleCode: $roleCode, practiceId: $practiceId, portalId: $portalId) {
          result {
            siteLogo
            siteLogoS3Token
          }
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      variables: { portalId: currentPortalId, practiceId: me.practiceid, roleCode: me.rolecode },
    }
  );

  const { loading, error, data: newsData, refetch } = useQuery(
    gql`
      query {
        newsList {
          result
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
    }
  );

  const { data: globalUserData } = useQuery(
    gql`
      query {
        globalUserDataList {
          result
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
    }
  );

  // RECENT COMPLETE LIST
  const [recenltyCompleteHandler, { loading: recentalyLoading, data: recentlyCompleteData, refetch: recentComplateRefetch }] = useLazyQuery(
    gql`
      query($filters: JSON) {
        getRecentlyCompletedAssessment(filters: $filters) {
          clients
          pagination {
            totalCount
          }
        }
      }
    `,
    {
      variables: {
        filters: {
          practiceId: me.practiceid,
          order: assessmentOrder,
          search: assessmentSearch,
          accountType: me.accountType,
          roleCode: me.rolecode,
          portalId: me.portalid.length > 0 ? me.portalid[0] : 0,
        },
      },
      fetchPolicy: "network-only",
      onCompleted: (data) => {},
    }
  );

  const [sentViewHandler, { loading: sentViewLoading, data: sentViewCompleteData, refetch: sentViewRefetch }] = useLazyQuery(
    gql`
      query($filters: JSON) {
        getSentViewedAssessment(filters: $filters) {
          clients
          pagination {
            totalCount
          }
        }
      }
    `,
    {
      variables: {
        filters: {
          practiceId: me.practiceid ? me.practiceid : null,
          advisorId: "",
          type: assessmentViewType,
          order: assessmentOrder,
          search: assessmentSearch,
          portalId: me.portalid.length > 0 ? me.portalid[0] : 0,
          accountType: me.accountType,
          roleCode: me.rolecode,
        },
      },
      fetchPolicy: "network-only",
      onCompleted: (data) => {},
    }
  );

  useEffect(() => {
    if (!logoDetail) return;

    let logo = logoDetail.getLogoByRole.result;
    logo = logo.siteLogo ? logo.siteLogo : desktopLogo;
    setLogoUpdate(logo);
  }, [logoDetail]);

  useEffect(() => {
    if (assessmentViewType == "completed") recenltyCompleteHandler();
    if (assessmentViewType && assessmentViewType !== "completed") sentViewHandler();
  }, [assessmentViewType, assessmentOrder, assessmentSearch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const newsList = _.get(newsData, "newsList.result", []);
  const userData = _.get(globalUserData, "globalUserDataList.result", []);
  let recentlyCompletedAssessment = _.get(recentlyCompleteData, "getRecentlyCompletedAssessment.clients", []);
  let sentAssessment = _.get(sentViewCompleteData, "getSentViewedAssessment.clients", []);

  const GetInitials = (name) => {
    const nameArray = name.split(" ");
    const initialsArray = [];

    nameArray.forEach((i) => initialsArray.push(i.charAt(0).toUpperCase()));

    return initialsArray.join("");
  };

  const handleUserLogout = () => {
    userContext.setAccount(null);
    userContext.setLogoUpdate(null);
    userContext.setProfile(null);
    userContext.setUserPermission([]);
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    history.push("/login");
  };

  const avatarHandler = () => {
    history.push("/settings/account");
  };

  const tabChangeHandler = (e) => {
    setAssessmentViewType(e);
    if (e == "completed") recenltyCompleteHandler();
    else {
      setAssessmentViewType(e);
    }
  };

  const setAssessmentOrderHandler = (data) => {
    setAssessmentOrder(data);
  };

  function onChange(value) {
    setSelect(value);
    if (!currentPractice && !currentPortalId) {
      setMenuModal(true);
      return;
    }
    if (value == "practice") setPracticeInviteModal(true);
    if (value == "assessment") setAssessmentModal(true);
    if (value == "client") setClientModal(true);
    if (value == "advisor") setAdvisorModal(true);
    setSelect(undefined);
  }

  const assessmentHandler = () => {
    setAssessmentModal(false);
    setTotalAssessment(null);
  };

  let roleName = "";
  if (me) {
    if (me.rolecode == "CMPYO") {
      roleName = "Company Admin";
    }
    if (me.rolecode == "CMPYM") {
      roleName = "Company member";
    }
    if (me.rolecode == "PRCTO") {
      roleName = "Practice Admin";
    }
    if (me.rolecode == "PRCTM") {
      roleName = "Practice Member";
    }
    if (me.rolecode == "ADVSO") {
      roleName = "Advisor Admin";
    }
    if (me.rolecode == "ADVSM") {
      roleName = "Advisor";
    }
  }
  let name = me && me.firstName && me.lastName ? `${me.firstName} ${me.lastName}` : "My Account";

  const profileContent = (
    <Card bordered={false} bodyStyle={{ padding: 0 }}>
      <Avatar size={64} style={{ margin: "auto", display: "block", backgroundColor: "rgba(101,122,133,.3)" }}>
        {GetInitials(name)}
      </Avatar>
      <div className="profile-wrapper" style={{ textAlign: "center" }}>
        <Link to={me.rolecode == "SPR" ? "#" : "/settings/account"}>
          <h6>{name}</h6>
          {/* <p>Admin Title</p> */}
        </Link>
      </div>
      <Divider style={{ margin: "10px 0" }} />
      <UserCardWrapper>
        <Button className={"profile-link"} type="danger" block onClick={() => handleUserLogout()} style={{ boxShadow: "none", backgroundColor: "transparent", borderColor: "transparent", color: "black" }}>
          <Icon type="logout" /> <IntlMessages id="topbar.userMenuLogout" />
        </Button>
      </UserCardWrapper>
    </Card>
  );

  return (
    <LandingDataWrapper>
      <div className="container-fluid g-0">
        <nav className="navbar navbar-expand-lg navbar-light">
          <a className="navbar-brand" href="#">
            <img src="/img/landing-logo.svg" alt="logo" />
          </a>
          <div className="d-flex align-items-center">
            <div className="quickLinksWrapper">
              <Select style={{ width: 145 }} placeholder="Quick Links" onChange={onChange} value={select}>
                <Option value="assessment" key={3}>
                  Send Assessment Reminder
                </Option>
                {me && ((me.accountType == 1 && me.rolecode != "ADVSO") || (me.accountType == 2 && (me.isPracticeAdmin || me.isPortalAdmin))) && (
                  <Option value="practice" key={1}>
                    Invite Practice Member
                  </Option>
                )}
                {me && (me.accountType == 1 || (me.accountType == 2 && (me.isPracticeAdmin || me.isPortalAdmin || me.isAdvisorAdmin))) && (
                  <Option value="advisor" key={2}>
                    Invite Advisor
                  </Option>
                )}
                <Option value="client" key={4}>
                  Send Assessment
                </Option>
              </Select>
            </div>
            <button className="navbar-toggler ms-2" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => menuToggle()}>
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto">
              {/* <li className="nav-item">
                <a className="nav-link" href="https://unitifi.com" target="_blank">
                  For Professionals
                </a>
              </li> */}
              <li className="nav-item">
                <a className="nav-link" href={process.env.REACT_APP_URL + "faq"}>
                  FAQs
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href={process.env.REACT_APP_URL + "faq#bottom"}>
                  contact
                </a>
              </li>
              <li className="nav-item">
                <Select style={{ width: 190 }} placeholder="Quick Links" onChange={onChange} value={select}>
                  <Option value="assessment" key={3}>
                    Send Assessment Reminder
                  </Option>
                  {me && ((me.accountType == 1 && me.rolecode != "ADVSO") || (me.accountType == 2 && (me.isPracticeAdmin || me.isPortalAdmin))) && (
                    <Option value="practice" key={1}>
                      Invite Practice Member
                    </Option>
                  )}
                  {me && (me.accountType == 1 || (me.accountType == 2 && (me.isPracticeAdmin || me.isPortalAdmin || me.isAdvisorAdmin))) && (
                    <Option value="advisor" key={2}>
                      Invite Advisor
                    </Option>
                  )}
                  <Option value="client" key={4}>
                    Send Assessment
                  </Option>
                </Select>
              </li>
              <li className="nav-item d-flex align-items-center">
                <Popover placement="bottomRight" content={profileContent} trigger="click">
                  <div className="user-menu d-flex align-items-center">
                    <div className="user-detail me-2">
                      <h6 className="mb-0">
                        {me.firstName} {me.lastName}
                      </h6>
                      <p>{roleName}</p>
                    </div>
                    <div className="user-menu-dropdown-link">
                      <Avatar
                        style={{
                          backgroundColor: "rgba(101,122,133,.3)",
                          fontWeight: "600",
                        }}>
                        {GetInitials(name)}
                      </Avatar>
                    </div>
                  </div>
                </Popover>
                {/* <div>
                  <span className="userTitle">Jane Doe</span>
                  <p className="mb-0">Admin Title</p>
                </div>
                <span className="profileAvtar ms-2">JD</span> */}
              </li>
            </ul>
          </div>
        </nav>

        <Drawer bodyStyle={{ background: "rgb(255,255,255)", padding: 0 }} className="mobile-menu-nav" closable={false} onClose={menuToggle} placement="right" style={{ padding: 0 }} visible={collapsed} width={256}>
          <MenuWrapper>
            <Menu mode="inline" theme="light">
              <Menu.Item className="mobile-menu-nav-link">
                <a href={"https://unitifi.com/faqs"} target="_blank">
                  <span>faqs</span>
                </a>
              </Menu.Item>
              <Menu.Item className="mobile-menu-nav-link">
                <a href={"https://unitifi.com/contact"} target="_blank">
                  <span>contact</span>
                </a>
              </Menu.Item>
              <Menu.Item className="mobile-menu-nav-link d-flex align-items-center" onClick={() => avatarHandler()}>
                <Avatar
                  style={{
                    backgroundColor: "rgba(101,122,133,.3)",
                    fontWeight: "600",
                  }}>
                  {GetInitials(name)}
                </Avatar>
                <div className="ms-3">
                  <span className="userTitle">
                    {me.firstName} {me.lastName}
                  </span>
                  <p className="mb-0">{roleName}</p>
                </div>
              </Menu.Item>
            </Menu>
          </MenuWrapper>
        </Drawer>

        <div className="sidemenu-trigger-wrapper collapsed" onClick={menuToggleSide} type="menu-unfold">
          {/* <Icon className="trigger collapsed" onClick={menuToggleSide} type="menu-unfold" /> */}
          <i className="trigger collapsed">
            <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2.16667 16.25H16.375C17.0854 16.25 17.6667 15.6687 17.6667 14.9583C17.6667 14.2479 17.0854 13.6667 16.375 13.6667H2.16667C1.45625 13.6667 0.875 14.2479 0.875 14.9583C0.875 15.6687 1.45625 16.25 2.16667 16.25ZM2.16667 9.79167H12.5C13.2104 9.79167 13.7917 9.21042 13.7917 8.5C13.7917 7.78958 13.2104 7.20833 12.5 7.20833H2.16667C1.45625 7.20833 0.875 7.78958 0.875 8.5C0.875 9.21042 1.45625 9.79167 2.16667 9.79167ZM0.875 2.04167C0.875 2.75208 1.45625 3.33333 2.16667 3.33333H16.375C17.0854 3.33333 17.6667 2.75208 17.6667 2.04167C17.6667 1.33125 17.0854 0.75 16.375 0.75H2.16667C1.45625 0.75 0.875 1.33125 0.875 2.04167ZM23.2208 12.22L19.5008 8.5L23.2208 4.78C23.7246 4.27625 23.7246 3.4625 23.2208 2.95875C22.7171 2.455 21.9033 2.455 21.3996 2.95875L16.7625 7.59583C16.2587 8.09958 16.2587 8.91333 16.7625 9.41708L21.3996 14.0542C21.9033 14.5579 22.7171 14.5579 23.2208 14.0542C23.7117 13.5504 23.7246 12.7237 23.2208 12.22Z"
                fill="white"
              />
            </svg>
          </i>
        </div>

        <Drawer bodyStyle={{ background: "rgb(255,255,255)", padding: 0 }} closable={false} onClose={menuToggleSide} placement="left" style={{ padding: 0 }} visible={!collapsedSide} width={256}>
          {/* show trigger inside drawer when menu sidebar is expanded */}
          {!collapsedSide ? (
            <div className="menu-trigger-wrapper expanded">
              <Icon className="trigger expanded" onClick={menuToggleSide} type="close" />
            </div>
          ) : null}
          <Sidebar
            {...props}
            collapsed={false}
            currentPractice={me ? me.currentpracticeid : null}
            currentPortalId={me ? me.portalid && me.portalid.length > 0 && me.portalid[0] : null}
            gblUserId={user.id}
            gblUserIsGlobalAdmin={user.isGlobalAdmin}
            gblUserIsPracticeAdmin={user.isPracticeAdmin}
            gblUserIsPortalAdmin={user.isPortalAdmin}
            gblUserRole={(user.role && user.role[0].id) || 0}
            location={location}
            menuToggle={menuToggleSide}
            isMobile
            openKeys=""
            selectedKeys=""
            showPracticeList=""
            screenWidth=""
          />
        </Drawer>

        <div className="banner-wrapper">
          <div className="bannerContent">
            <h2>Unite with your clients through more effective communication.</h2>
            {/* <div className="signupLink">
              <Button className="inviteBtn" onClick={() => setClientModal(true)}>
                Invite Clients
              </Button>
            </div> */}
          </div>
        </div>

        <div className="companyInfoWrapper">
          <Container>
            <Row gutter={50}>
              <Col sm={12} xl={6}>
                <div className="companyInfoBox cmn-info-box" onClick={() => history.push(currentPortalId && currentPractice ? `/${currentPractice}/clients` : "#")}>
                  <img src="/img/icon-client.svg" className="clientImg" />
                  <p>Clients</p>
                </div>
              </Col>
              <Col sm={12} xl={6}>
                <div className="companyInfoBox cmn-info-box" onClick={() => history.push(currentPortalId && currentPractice ? "/advisors" : "#")}>
                  <img src="/img/icon-advisor.svg" className="cmnImg" />
                  <p>Advisors</p>
                </div>
              </Col>
              <Col sm={12} xl={6}>
                <div className="companyInfoBox cmn-info-box" onClick={() => history.push(currentPortalId && currentPractice ? `/retirement-vehicle/401(k)_at_work` : "#")}>
                  <img src="/img/icon-retire-vehicle.svg" className="cmnImg" />
                  <p>Retirement Vehicle</p>
                </div>
              </Col>
              <Col sm={12} xl={6}>
                <div className="companyInfoBox cmn-info-box" onClick={() => setClientModal(true)}>
                  <img src="/img/icon-client-invite.svg" className="cmnImg" />
                  <p>Invite Client</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="servicesDataWrapper">
          <Container>
            <Row gutter={26}>
              <Col xl={16}>
                <div className="dashboardSectionWrapper mb-4 mb-xl-0">
                  <div className="dashboardBoxWrapper">
                    <div className="dashboardHeading">
                      <h6>Quick View</h6>
                      <Button type="primary" className="dashboardBtn" onClick={() => history.push("/")}>
                        Go To Dashboard
                      </Button>
                    </div>
                    <div className="topbar-wrapper">
                      <div className="top-bar">
                        <div className="fw-bold">Conservative</div>
                        <div className="fw-bold">Aggressive</div>
                      </div>
                    </div>
                    <div class="dashboard-main-box-wrapper">
                      <div class="dashboard-info-item protector">
                        <div class="">
                          <div class="dashboard-info-title">Protector</div>
                          <div class="dashboard-info-detail">15%</div>
                        </div>
                      </div>
                      <div class="dashboard-info-item observer">
                        <div class="">
                          <div class="dashboard-info-title">Observer</div>
                          <div class="dashboard-info-detail">35%</div>
                        </div>
                      </div>
                      <div class="dashboard-info-item liberator">
                        <div class="">
                          <div class="dashboard-info-title">Liberator</div>
                          <div class="dashboard-info-detail">35%</div>
                        </div>
                      </div>
                      <div class="dashboard-info-item energizer">
                        <div class="">
                          <div class="dashboard-info-title">Energizer</div>
                          <div class="dashboard-info-detail">35%</div>
                        </div>
                      </div>
                    </div>

                    <TotalClientMap data={clientMapData} newscenterclass="news-center-label" allowClick={false} />

                    <div className="graph-bottom-bar gradientBg">
                      <span>Conservative</span>
                      <span>Moderately Conservative</span>
                      <span>Moderate</span>
                      <span>Moderately Aggressive</span>
                      <span>Aggressive</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={8}>
                <div className="dashboardSectionWrapper">
                  <div className="dashboardBoxWrapper feedBox">
                    <div className="dashboardHeading p-0 mb-3">
                      <h6>Assessment Activity</h6>
                      <Button type="primary" className="dashboardBtn viewBtn" onClick={() => setAssessmentViewAllModal(true)}>
                        View All
                      </Button>
                    </div>
                    <div className="feedInfoWrapper">
                      <p className="time-detail mb-0 mt-3">58 Minutes ago</p>
                      <p className="client-title">Jane Doe</p>
                      <p className="client-title style-data">Moderately Aggressive Liberator</p>
                      <p className="client-title retirement-data mb-3">Work Retirement Plan</p>
                      {/* <p className="view-data mb-0"><a href="#" className="mb-3">
                          View
                        </a></p> */}
                    </div>
                    <div className="feedInfoWrapper">
                      <p className="time-detail mb-0 mt-3">58 Minutes ago</p>
                      <p className="client-title">Jane Doe</p>
                      <p className="client-title style-data">Moderately Aggressive Liberator</p>
                      <p className="client-title retirement-data mb-3">Work Retirement Plan</p>
                      {/* <p className="view-data mb-0">
                        <a href="#" className="mb-3">
                          View
                        </a>
                      </p> */}
                    </div>
                    <div className="feedInfoWrapper">
                      <p className="time-detail mb-0 mt-3">58 Minutes ago</p>
                      <p className="client-title">Jane Doe</p>
                      <p className="client-title style-data">Moderately Aggressive Liberator</p>
                      <p className="client-title retirement-data mb-3">Work Retirement Plan</p>
                      {/* <p className="view-data mb-0">
                        <a href="#" className="mb-3">
                          View
                        </a>
                      </p> */}
                    </div>

                    <div className="feedInfoWrapper">
                      <p className="time-detail mb-0 mt-3">58 Minutes ago</p>
                      <p className="client-title">Jane Doe</p>
                      <p className="client-title style-data">Moderately Aggressive Liberator</p>
                      <p className="client-title retirement-data mb-3">Work Retirement Plan</p>
                      {/* <p className="view-data mb-0">
                        <a href="#" className="mb-3">
                          View
                        </a>
                      </p> */}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            {/* <div className="logoSlideWrapper">
              <div class="">
                <Slider {...settings}>
                  <div class="slide">
                    <img src="/img/slider-logo.png" />
                  </div>
                  <div class="slide">
                    <img src="/img/slider-logo.png" />
                  </div>
                  <div class="slide">
                    <img src="/img/slider-logo.png" />
                  </div>
                  <div class="slide">
                    <img src="/img/slider-logo.png" />
                  </div>
                </Slider>
              </div>
            </div> */}

            <div className="companyInfoWrapper">
              <Container>
                <h4 className="heading-title">Unitifi Stats</h4>
                <Row gutter={50}>
                  <Col sm={12} xl={6}>
                    <div className="companyInfoBox">
                      <img src="/img/icon-assessment.svg" class="compImg" />
                      <h4>{userData.length > 0 ? userData.filter((data) => data.type == "assessment").map((data) => data.total) : 0}</h4>
                      <p>Assessments Completed</p>
                    </div>
                  </Col>
                  <Col sm={12} xl={6}>
                    <div className="companyInfoBox">
                      <img src="/img/icon-practice.svg" class="compImg" />
                      <h4>{userData.length > 0 ? userData.filter((data) => data.type == "practice").map((data) => data.total) : 0}</h4>
                      <p>Practices</p>
                    </div>
                  </Col>
                  <Col sm={12} xl={6}>
                    <div className="companyInfoBox">
                      <img src="/img/advisor-icon.svg" />
                      <h4>{userData.length > 0 ? userData.filter((data) => data.type == "advisor").map((data) => data.total) : 0}</h4>
                      <p>Advisors</p>
                    </div>
                  </Col>
                  <Col sm={12} xl={6}>
                    <div className="companyInfoBox">
                      <img src="/img/client-icon.svg" class="compImg" />
                      <h4>{userData.length > 0 ? userData.filter((data) => data.type == "client").map((data) => data.total) : 0}</h4>
                      <p>Clients</p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>

            {newsList.length > 0 && (
              <div className="newsSectionWrapper mt-5">
                <h4>News</h4>
                <Row gutter={30}>
                  <Col xl={12}>
                    <div className="newsSectionBannerBox leftImage">
                      <img src={newsList[0].coverImage} />
                    </div>
                    <div className="newsBannerContent">
                      <h4>
                        <a href={newsList[0].url} target="_blank">
                          {newsList[0].title}
                        </a>
                      </h4>
                      <p>
                        <a href={newsList[0].url} target="_blank">
                          {newsList[0].description}
                        </a>
                      </p>
                      <span>
                        <a href={newsList[0].url} target="__blank">
                          Read More
                          <img src="/img/icon-arrow.png" className="ms-3" />
                        </a>
                      </span>
                    </div>
                  </Col>
                  {newsList.length > 1 && (
                    <Col xl={12}>
                      <Row>
                        <Col xl={24}>
                          <div className="newsSectionBannerBox rightImage mb-4">
                            <img src={newsList[1].coverImage} />
                          </div>
                          <div className="newsBannerContent rightBox">
                            <h4>
                              <a href={newsList[1].url} target="_blank">
                                {newsList[1].title}
                              </a>
                            </h4>
                            <p className="rightBoxInfo">
                              <a href={newsList[1].url} target="_blank">
                                {newsList[1].description}
                              </a>
                            </p>
                            <span>
                              <a href={newsList[1].url} target="_blank">
                                Read More
                                <img src="/img/icon-arrow.png" className="ms-3" />
                              </a>
                            </span>
                          </div>
                        </Col>
                      </Row>
                      {newsList.length > 2 && (
                        <Row>
                          <Col xl={24}>
                            <div className="newsSectionBannerBox rightImage mt-1">
                              <img src={newsList[2].coverImage} />
                            </div>
                            <div className="newsBannerContent rightBox">
                              <h4 className="rightBoxTitle">
                                <a href={newsList[2].url} target="_blank">
                                  {newsList[2].title}
                                </a>
                              </h4>
                              <span>
                                <a href={newsList[2].url} target="_blank">
                                  Read More
                                  <img src="/img/icon-arrow.png" className="ms-3" />
                                </a>
                              </span>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  )}
                </Row>
              </div>
            )}

            <span className="footerCopyright">Copyright © {moment().format("YYYY")} Unitifi</span>
          </Container>
        </div>

        {/* <div className="footerLayoutWrapper">
          <div className="footer-social-links">
            <span className="socialBox">
              <a href="https://www.linkedin.com/company/unitifi/" target="_blank">
                <img src="/img/linkedin-icon.svg" />
              </a>
            </span>
            <span className="socialBox">
              <a href="https://twitter.com/unitifi_" target="_blank">
                <img src="/img/twitter-icon.svg" />
              </a>
            </span>
            <span className="socialBox">
              <a href="https://www.facebook.com/unitifi" target="_blank">
                <img src="/img/facebook-icon.svg" />
              </a>
            </span>
            <span className="socialBox">
              <a href="mailto:info@unitifi.com">
                <img src="/img/envelope-icon.svg" />
              </a>
            </span>
            <span className="socialBox">
              <a href="https://youtube.com/@unitifi" target="_blank">
                <img src="/img/youtube-icon.svg" />
              </a>
            </span>
          </div>

          <div className="footer-quicklinks">
            <ul className="list-unstyled">
              <li>
                <a href="https://meetings.hubspot.com/todd-cutler" target="_blank">
                  Schedule a meeting
                </a>
              </li>
              <li>
                <Link to={"/signup_new"}>sign up today!</Link>
              </li>
              <li>
                <Link to={"/login"}>Member Login</Link>
              </li>
              <li>
                <a href="https://unitifi.com/terms" target="_blank">
                  terms of use
                </a>
              </li>
              <li>
                <a href="https://unitifi.com/contact" target="_blank">
                  contact
                </a>
              </li>
            </ul>
          </div>

          <div className="footer-quicklinks contactInfo">
            <ul className="list-unstyled">
              <li>Unitifi, 12020 shamrock plaza #200, omaha, ne 68154, usa </li>
              <li>402-979-7185</li>
            </ul>
            <span>&#169;2023 Unitifi | All right reserved</span>
          </div>
        </div> */}
      </div>
      <Modal
        show={isClientModal}
        onHide={() => {
          setClientModal(false);
        }}
        centered
        className="unitifi-modal-wrapper client-modal">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">Send Assessment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InviteClient setClientModal={setClientModal} currentPortalId={props.currentPortalId} setAssessmentChange={setAssessmentChange} gblUserId={props.gblUserId} {...props} me={me} />
        </Modal.Body>
      </Modal>
      <Modal show={isAssessmentViewAllModal} onHide={() => setAssessmentViewAllModal(false)} centered className="unitifi-modal-wrapper notify-modal vehicle-data" size="lg">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <AssessmentModal
            recentlyCompletedAssessment={recentlyCompletedAssessment}
            {...props}
            tabChangeHandler={tabChangeHandler}
            sentAssessment={sentAssessment}
            setAssessmentSearch={setAssessmentSearch}
            setAssessmentOrderHandler={setAssessmentOrderHandler}
            assessmentViewType={assessmentViewType}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={isPracticeInviteModal}
        onExited={() => {
          setPracticeInviteModal(false);
          setSelect(undefined);
          setExceedalert(false);
        }}
        onHide={() => {
          setPracticeInviteModal(false);
          setSelect(undefined);
          setExceedalert(false);
        }}
        centered
        className="unitifi-modal-wrapper">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Invite Practice Member(s)
            {exceedalert && (
              <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={popover}>
                <i className="ms-2">
                  <img src={infoIcon} />
                </i>
              </OverlayTrigger>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InvitePractice setPracticeInviteModal={setPracticeInviteModal} exceedalert={exceedalert} setExceedalert={setExceedalert} currentPortalId={props.currentPortalId} gblUserId={props.gblUserId} {...props} data={{ me: me }} />
        </Modal.Body>
      </Modal>
      <Modal
        show={isAssessmentModal}
        onHide={() => {
          assessmentHandler();
          setSelect(undefined);
        }}
        size="lg"
        centered
        className="unitifi-modal-wrapper modalAssessment">
        <Modal.Header className="p-0">
          {/* <Modal.Title id="example-modal-sizes-title-sm"> Assessment Reminder</Modal.Title> */}
          {totalAssessment && (
            <p className="selectAssessment mb-0" onClick={() => assessmentRef.current.selectAll({ value: 0, label: `Send assessment to all selected (${totalAssessment})` })}>
              Select All({totalAssessment})
            </p>
          )}
        </Modal.Header>
        {/* <Modal.Body> */}
        <InviteAssessment ref={assessmentRef} setAssessmentModal={setAssessmentModal} setTotalAssessment={setTotalAssessment} currentPortalId={props.currentPortalId} gblUserId={props.gblUserId} {...props} me={me} />
        {/* </Modal.Body> */}
      </Modal>
      <Modal
        show={isAdvisorModal}
        onExited={() => {
          setAdvisorModal(false);
          setSelect(undefined);
          setExceedalert(false);
        }}
        onHide={() => {
          setAdvisorModal(false);
          setSelect(undefined);
          setExceedalert(false);
        }}
        centered
        className="unitifi-modal-wrapper">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Invite Advisor(s)
            {exceedalert && (
              <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={popover}>
                <i className="ms-2">
                  <img src={infoIcon} />
                </i>
              </OverlayTrigger>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InviteAdvisor setAdvisorModal={setAdvisorModal} exceedalert={exceedalert} setExceedalert={setExceedalert} currentPortalId={props.currentPortalId} me={me} gblUserId={props.gblUserId} {...props} />
        </Modal.Body>
      </Modal>
    </LandingDataWrapper>
  );
};

export default Landing;
